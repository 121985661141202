<template>
    <div class="bg-primary">
        <div class="container full-height p-4 fl-y-cc text-black-50">
            <div class="card contact-form py-4 text-black-50">
                <transition name="fade" mode="out-in">
                    <s-form key="'login" role="form" @submit="loginClicked" class="form-sm">
                        <div class="fl-x fl-j-c">
                            <img src="../../assets/img/logo/logo.png" class="filter-invert logo-img mb-4 px-5" alt="">
                        </div>
                        <div class="text-center">
                            <h3 class="text-center text-primary">Login</h3>
                            <p class="text-center text-secondary">Login with your Credential</p>
                        </div>
                        <validated-input v-model="model.email" type="email" class="mx-4" label="Email"/>
                        <validated-input v-model="model.password" type="password" label="Password" class="mx-4 mb-3"/>
                        <div class="bg-danger p-3 mx-4 round-1" style="text-align: center;" v-if="errorMessage"
                             v-html="errorMessage"></div>
<!--                        <btn color="primary" :loading="loading" class="w-50 px-5"-->
<!--                             loading-text="Validating Credential.."-->
<!--                             text="Login"/>-->
                        <div class="mt-3 text-center fl-x fl-j-c px-4">
                                                        <btn color="primary" type="submit" size="md" block :loading="loading" class="px-6"
                                                             loading-text="Validating Credential.."
                                                             text="Login"/>
                            <!--                            <btn design="basic-b" class="px-5" @click="switchForms('update')"-->
                            <!--                                 text="Update Password"></btn>-->
                        </div>
                    </s-form>
                    <s-form key="'update" role="form" @submit="onSubmit"
                            class="card contact-form py-4 text-black-50"
                            style="width: 500px">
                        <div class="fl-x fl-j-c">
                            <img src="../../assets/img/logo/logo.png" class="filter-invert logo-img mb-4 px-5" alt="">
                        </div>
                        <div class="text-center">
                            <h3 class="text-center text-primary">Update Password</h3>
                            <small class="text-center">Update Your Password</small>
                        </div>
                        <validated-input v-model="model.username" type="Password" class="mx-4"
                                         label="Create New Password"/>
                        <validated-input v-model="model.password" type="password" label="Confirm Password"
                                         class="mx-4 mb-3"/>
                        <div class="bg-danger p-3 mx-4" style="text-align: center;" v-if="errorMessage"
                             v-html="errorMessage"></div>
                        <div class="mt-3 text-center fl-x fl-j-sb px-4">
                            <btn color="primary" class="px-5" text="Update"></btn>
                            <btn color="primary" design="basic-b" @click="switchForms('login')" :loading="loading"
                                 class="px-5" text="Cancel"/>
                        </div>
                    </s-form>
                </transition>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'secure-axios';
import urls from '../../data/urls';
import { mapActions } from 'vuex';

export default {
    name: 'Login',
    props: {
        info_message: {
            type: String,
            default: ''
        }
    },
    data () {
        return {
            loading: false,
            loginUrl: urls.auth.login,
            errorMessage: '',
            model: {
                email: '',
                password: ''
            },
            rules: {
                email: {
                    required: true
                },
                password: {
                    required: true
                }
            }
        };
    },
    methods: {
        ...mapActions(['setUser']),
        async loginClicked () {
            const that = this;
            that.loading = true;
            const response = await axios.form(urls.auth.login, that.model);
            const json = response.data;
            console.log('data', json);
            if (json.email) {
                const redirectUrl = sessionStorage.getItem('redirectPath');
                that.setUser(json);
                that.$router.push(redirectUrl || { path: '/admin/' });
                that.loading = false;
            } else {
                that.errorMessage = 'Invalid Credentials';
                that.loading = false;
            }
            that.loading = false;
            localStorage.clear();
        }
    }
};
</script>

<style scoped>
.logo-img {
    width: 16.75rem;
}
</style>
